import React from 'react'
import { ButtonArrow } from '../../components/buttons/_btn_arrow';

import { TextGradient } from '../../components/_text/_text_gradient';
import Loadable from "@loadable/component"
import { Picture } from 'react-responsive-picture';

const AwesomeSwiper = Loadable(() => import("react-awesome-swiper"));



const config = {
    // width: 800,
    loop : true,
    autoplay: {
      delay: 300000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      bulletElement : 'li',
      hideOnClick :false,
      clickable :true,
    },
    on: {
      slideChange: function () {
        // console.log(this.activeIndex);
      },
    },
  };

class BlogCarousel extends React.Component{
    swiperRef = null
    create_dynamics_items(_items){
      const lastItem = _items.edges.slice(0, 5)
        return lastItem.map((_item,_index)=>{
            return (<div key={`carousel-${_item.node.uid}`} className="swiper-slide blog_carrousel_item">
              <TextGradient
                _text={_item.node.data.title.text}
                _color_start='#d70b2d'
                _color_end='#bc0c2f'
                _deg={180}
                _className={'blog_carrousel_item__title'} />
              <Picture
                sources = {[
                  {
                    srcSet: _item.node.data.main_image.sm.url,
                    media: "(max-width: 768px)",
                  },
                  {
                      srcSet: _item.node.data.main_image.md.url,
                      media: "(max-width: 1279px)",
                  },
                  {
                      srcSet: _item.node.data.main_image.lg.url,
                      media: "(max-width: 1475px)",
                  },
                  {
                      srcSet: _item.node.data.main_image.url,
                      media: "(min-width: 1476px)",
                  }
                ]}
              />
                <ButtonArrow _className='blog_carrousel_item__vermas' _to={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`} _text='Leer más' section={_item.node.uid}/>
            </div>
            )
        });
    };

    render(){
        if(this.props.datablogs!==undefined)
        {
          return (
            <AwesomeSwiper ref={this.swiperRef} config={config}>
                <div className="swiper-wrapper">
                    {this.create_dynamics_items(this.props.datablogs)}
                </div>
                <div className="swiper-pagination"></div>
            </AwesomeSwiper>
        );
        }
      else{
        return (<></>)
      }
    }
}

export default BlogCarousel